@import "../../../utils/theme.scss";

.imagePopUpLayer__container__data {
  display: flex;
  width: 100%;
  height: 100%;
  .imagePopUpLayer__container__img {
    width: 100%;
    height: 115.6%;
    object-fit: cover;
    cursor: pointer;
  }

}
