@import "../../utils/theme.scss";

.container {
  width: 100%;
  height: 120px;
  background-color: var(--border-bottom-color);
  border-radius: 7px;
  padding: 0 10px;
  .container_top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .container_top_subtitle {
      font-weight: 300;
      color: var(--title-color);
      font-size: 18px;
      padding: 15px 0;
    }
  }
}
