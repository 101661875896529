@import "../../utils/theme.scss";

.reality_project {
  padding: 25px 0;
  .reality_project__box {
    width: 100%;
    margin: 0 auto;
    @media only screen and (max-width: 550px) {
    }
    @media only screen and (min-width: 550px) {
    }
    @media only screen and (min-width: 768px) {
      max-width: 720px;
      width: 90%;
    }
    @media only screen and (min-width: 1024px) {
      max-width: 980px;
      width: 90%;
    }
    @media only screen and (min-width: 1280px) {
      max-width: 1280px;
      width: 90%;
    }
  }
  background-color: #333;
  width: 100%;
  margin: 0;
  .reality_project__title {
    display: block;
    text-align: center;
    line-height: 35px;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: var(--white-title-font-color);
    background-color: transparent;
    text-decoration: none;
    font-variant: normal;
    font-size: 28px;
    padding: 20px 0;
  }
}

.reality_project__image {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}

// TODO: use for projectImgBox
// @media only screen and (max-width: 550px) {
//   .reality_project__image__box {
//     width: 100%;
//   }
//   .reality_project__image a {
//     width: 100%;
//     padding: 0 30px;
//   }
//   .reality_project__image__box__image {
//     width: 100%;
//     height: 100%;
//   }
// }
// @media only screen and (min-width: 550px) {
//   .reality_project__image__box {
//     width: calc(100% / 2);
//   }
//   .reality_project__image a {
//     width: 100%;
//     padding: 0 12.5px;
//   }
//   .reality_project__image__box__image {
//     width: 100%;
//     height: 100%;
//   }
// }
// @media only screen and (min-width: 768px) {
//   .reality_project__image__box {
//     width: calc(100% / 2);
//   }
// }
// @media only screen and (min-width: 1024px) {
//   .reality_project__image__box {
//     width: calc(100% / 3);
//   }
// }
