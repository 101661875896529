// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&display=swap");

@font-face {
  font-family: "Noto Sans JP";
  font-weight: 100;
  src: url("./fonts/Noto_Sans_JP/NotoSansJP-Thin.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-weight: 300;
  src: url("./fonts/Noto_Sans_JP/NotoSansJP-Light.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-weight: 400;
  src: url("./fonts/Noto_Sans_JP/NotoSansJP-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-weight: 500;
  src: url("./fonts/Noto_Sans_JP/NotoSansJP-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-weight: 700;
  src: url("./fonts/Noto_Sans_JP/NotoSansJP-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-weight: 900;
  src: url("./fonts/Noto_Sans_JP/NotoSansJP-Black.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans TC";
  font-weight: 100;
  src: url("./fonts/Noto_Sans_TC/NotoSansTC-Thin.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans TC";
  font-weight: 300;
  src: url("./fonts/Noto_Sans_TC/NotoSansTC-Light.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans TC";
  font-weight: 400;
  src: url("./fonts/Noto_Sans_TC/NotoSansTC-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans TC";
  font-weight: 500;
  src: url("./fonts/Noto_Sans_TC/NotoSansTC-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans TC";
  font-weight: 700;
  src: url("./fonts/Noto_Sans_TC/NotoSansTC-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans TC";
  font-weight: 900;
  src: url("./fonts/Noto_Sans_TC/NotoSansTC-Black.otf") format("opentype");
}
@import "ionicons.min.scss";

:root {
  --primary-bg-color: #fff;
  --second-bg-color: #fbfbfb;
  --button-bg-color: #444;
  --primary-button-title-color: #fff;
  --second-button-title-color: #444;
  --third-button-title-color: #5d5d5d;
  --primary-button-hover-bg-color: #e0dede;
  --second-button-hover-bg-color: #444;
  --second-button-hover-color: #fff;
  --top-bg-color: rgba(255, 255, 255, 0.918);
  --top-nav-bg-color: #fff;
  --phone-top-bottom-color: #ccc;
  --phone-top-nav-bg-color: #fff;
  --phone-top-nav-col-line-color: #444;
  --phone-top-nav-col-title-color: #444;
  --phone-top-nav-col-text-color: #444;
  --primary-burger-icon-bg-color: #444;
  --second-burger-icon-bg-color: #fff;
  --main-between-line-color: #ccc;
  --title-color: #444;
  --subtitle-bg: #5d5d5d;
  --white-title-font-color: #fff;
  --grey-cotainer-bg-color: #333;
  --white-font-color: #fff;
  --cover-background-color: #818cab;
  --border-bottom-color: #e1e1e1;
  --footer-bg-color: #f2f2f2;
  --subject-blue: #177cc4;
  --second-bottom-bg-color: #f7f7f7;
  --second-bottom-color: #444;
  --second-bottom-hover-bg-color: #444;
  --second-bottom-hover-color: #f7f7f7;
  --popup-bg-color: rgba(255, 255, 255, 0.85);
  --holiday-font-color: #d8403f;
  --select-color: #fff;
  --select-bg-color: #666;
  --success-green: #74f466;
}

[data-theme="dark"] {
  --primary-bg-color: #212121;
  --second-bg-color: #181818;
  --button-bg-color: #313131;
  --primary-button-title-color: #fff;
  --second-button-title-color: #fff;
  --third-button-title-color: #ececec;
  --primary-button-hover-bg-color: rgb(70, 70, 70);
  --second-button-hover-bg-color: rgb(70, 70, 70);
  --second-button-hover-color: #fff;
  --top-bg-color: #181818ea;
  --top-nav-bg-color: #212121;
  --phone-top-bottom-color: #2b2b2b;
  --phone-top-nav-bg-color: #212121;
  --phone-top-nav-col-line-color: #444;
  --phone-top-nav-col-title-color: #fff;
  --phone-top-nav-col-text-color: #fff;
  --primary-burger-icon-bg-color: #fff;
  --second-burger-icon-bg-color: #fff;
  --main-between-line-color: #333333;
  --title-color: #fff;
  --subtitle-bg: #fbfbfb;
  --white-title-font-color: #fff;
  --grey-cotainer-bg-color: #333;
  --white-font-color: #fff;
  --cover-background-color: #818cab;
  --border-bottom-color: #303030;
  --footer-bg-color: #232323;
  --second-bottom-bg-color: #444;
  --second-bottom-color: #f7f7f7;
  --second-bottom-hover-bg-color: #f7f7f7;
  --second-bottom-hover-color: #444;
  --popup-bg-color: rgba(0, 0, 0, 0.85);
  --subject-blue: #177cc4;
  --holiday-font-color: #d8403f;
  --select-color: #000000;
  --select-bg-color: #ffffff;
  --success-green: #74f466;
}
