@import "../../../utils/theme.scss";

.container {
  width: 100%;
  height: 60vh;
}
.pageContainer_TopContainer_banner_slide {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageContainer_TopContainer_banner_slide_img {
  position: relative;
  object-fit: cover;
  height: 100%;
  width: 100%;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.pageContainer_TopContainer_banner_slide_locationName {
  position: absolute;
  display: flex;
  color: var(--white-font-color);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.9), 0 0 25px rgba(0, 0, 0, 0.9);
  font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
  font-weight: 700;
  line-height: 1.3em;
  text-align: right;
  white-space: pre-line;
  right: 40px;
  bottom: 50px;
  @media only screen and (max-width: 550px) {
    font-size: 1.5em;
    right: 20px;
  }
  @media only screen and (min-width: 550px) {
    font-size: 1.5em;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 2em;
  }
  @media only screen and (min-width: 1280px) {
    font-size: 2.5em;
  }
}
