@import "../../../utils/theme.scss";

.discover__box {
  padding: 36px 0 0 0;
  @media only screen and (max-width: 550px) {
    padding: 36px 30px;
  }
}
.discover__row {
  display: flex;
  justify-content: center;
  align-items: center;
  .discover__subtitle {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 90%;
    margin-bottom: 40px;
    line-height: 28px;
    padding: 15px;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: var(--subtitle-bg);
    border-bottom: 1px solid var(--border-bottom-color);
    background-color: transparent;
    text-decoration: none;
    font-variant: normal;
    font-size: 28px;
    @media only screen and (max-width: 550px) {
      font-size: 23px;
      margin-left: 0px;
    }
  }
}

.discover__content {
  display: flex;
  flex-wrap: wrap;
}
