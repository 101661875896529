@import "../../utils/theme.scss";

.main {
  margin: 0 auto;
  padding: 60px 0 30px 0;
  background-color: var(--primary-bg-color);
  transition: 0.5s;
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
  @media only screen and (min-width: 550px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    max-width: 720px;
    width: 90%;
    border-left: 1px solid var(--main-between-line-color);
    border-right: 1px solid var(--main-between-line-color);
  }
  @media only screen and (min-width: 1024px) {
    max-width: 980px;
    width: 90%;
    border-left: 1px solid var(--main-between-line-color);
    border-right: 1px solid var(--main-between-line-color);
  }
  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
    width: 90%;
    border-left: 1px solid var(--main-between-line-color);
    border-right: 1px solid var(--main-between-line-color);
  }
}

.main__nav {
  display: none;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.discover__box {
  padding: 36px 0 0 0;
  @media only screen and (max-width: 550px) {
    padding: 36px 30px;
  }
}
.discover__news {
  margin: 0 12.5px 25px;
  border: 3px solid var(--main-between-line-color);
  border-radius: 5px;
  padding: 15px 0;
  @media only screen and (max-width: 550px) {
    padding: 15px 0;
  }
  .discover__news_dayContainer {
    width: 100%;
    .discover__news__dayHeader {
      display: flex;
      justify-content: center;
      width: 100%;
      .discover__news__dayHeader_day {
        vertical-align: middle;
        margin: 0;
        min-width: 305px;
        li {
          width: 50%;
          text-align: center;
          display: inline-block;
        }
        li:nth-child(1) {
          float: right;
          font-size: 5em;
          vertical-align: top;
          line-height: 1em;
          font-weight: 600;
          letter-spacing: -0.04em;
          color: var(--title-color);
        }
        li:nth-child(2) {
          float: left;
          font-size: 1.5em;
          margin-top: 15px;
          vertical-align: middle;
          line-height: 25px;
          color: var(--subtitle-bg);
          text-align: right;
          padding: 0 5px;
        }
        li:nth-child(3) {
          float: left;
          font-size: 1.5em;
          vertical-align: top;
          line-height: 25px;
          font-weight: 800;
          text-transform: uppercase;
          color: var(--subtitle-bg);
          padding: 0 5px;
          text-shadow: none;
          text-align: right;
        }
      }
      .discover__news__dayHeader_weatherTime {
        position: relative;
        width: 100%;
        max-width: 410px;
        &:hover {
          background-color: var(--primary-button-hover-bg-color);
          transition: 0.5s;
        }
        .discover__news__dayHeader_weatherTime_weatherIcon {
          position: absolute;
          width: 85px;
          height: 100%;
          padding-bottom: 4%;
        }
      }
      .discover__news__dayHeader_time {
        vertical-align: middle;
        margin: 0;
        transition: 0.5s;
        li {
          width: 50%;
          text-align: center;
          display: inline-block;
        }
        sub {
          font-size: 0.2em;
          letter-spacing: -1.9px;
        }
        li:nth-child(1) {
          float: right;
          font-size: 5em;
          vertical-align: top;
          line-height: 1em;
          font-weight: 600;
          letter-spacing: -0.04em;
          color: var(--title-color);
        }
        li:nth-child(2) {
          float: left;
          font-size: 1.5em;
          margin-top: 10px;
          vertical-align: middle;
          line-height: 25px;
          font-weight: 800;
          color: var(--subtitle-bg);
          text-align: right;
          padding: 0 5px;
        }
        li:nth-child(3) {
          float: left;
          font-size: 1.5em;
          vertical-align: top;
          line-height: 25px;
          font-weight: 300 !important;
          text-transform: uppercase;
          color: var(--subtitle-bg);
          padding: 0 5px;
          text-shadow: none;
          text-align: right;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      .discover__news__dayHeader {
        display: grid;
        .discover__news__dayHeader_weatherTime {
          .discover__news__dayHeader_weatherTime_weatherIcon {
            width: 100px;
            padding-bottom: 6%;
          }
        }
        .discover__news__dayHeader_day,
        .discover__news__dayHeader_time {
          min-width: 296px;

          li:nth-child(1) {
            font-size: 3.5em;
            height: 80px;
          }
          li:nth-child(2) {
            font-size: 1.3em;
          }
          li:nth-child(3) {
            font-size: 1.3em;
          }
        }
        .discover__news__dayHeader_time {
          li:nth-child(2) {
            margin-top: 4px;
            line-height: 20px;
          }
        }
        .discover__news__dayHeader_day {
          li:nth-child(1) {
            text-indent: -0.5em;
          }
          li:nth-child(2) {
            margin-top: 5px;
          }
        }
      }
    }
    .discover__news__dayHeader_holiday {
      text-align: center;
      padding-bottom: 20px;
      .discover__news__dayHeader_holiday_first {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        color: var(--title-color);
      }
      .discover__news__dayHeader_holiday_second {
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        color: var(--holiday-font-color);
      }
    }
  }
  .discover__news_display {
    display: flex;
    width: 100%;
    justify-content: center;
    max-height: 334px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      margin: 15px;
    }
    .discover__news_display_table {
      width: 100%;
      margin: 0 30px 0 30px;
      .discover__news_display_table_row {
        position: relative;
        display: flex;
        border-bottom: 1px solid var(--main-between-line-color);
        transition: 0.5s;
        &-hover:hover {
          background-color: var(--primary-button-hover-bg-color);
          transition: 0.5s;
          cursor: pointer;
        }
        .discover__news_display_table_row_arrow {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          right: 5px;
          &::after {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            border-top: 2px solid var(--third-button-title-color);
            border-right: 2px solid var(--third-button-title-color);
            transform: rotate(45deg);
          }
        }
        .discover__news_display_table_col {
          font-size: 20px;
          font-weight: 300;
          line-height: 25px;
          color: var(--title-color);
          padding: 8px 0 8px 14px;
          line-height: 1.3em;
          &-bullet {
            align-self: center;
            font-family: "微軟正黑體", sans-serif;
            color: var(--subject-blue);
            font-size: 45px;
            line-height: 10px;
          }
          &-first {
            width: 160px;
          }
          &-second {
            width: 100%;
            max-height: 65px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding-right: 25px;
          }
          @media only screen and (max-width: 550px) {
            font-size: 16px;
            padding: 8px 0 8px 8px;
            &-bullet {
              color: var(--subject-blue);
              font-size: 35px;
              line-height: 10px;
              letter-spacing: -0.4em;
              padding: 0 8px 0 0;
            }
            &-second {
              max-height: 51px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              padding-right: 20px;
            }
          }
        }
      }
      .discover__news_display_table_row:last-child {
        border-bottom: 0;
      }
    }
  }
  .discover__title {
    display: block;
    padding: 36px 0;
    text-align: center;
    line-height: 35px;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: var(--title-color);
    text-decoration: none;
    font-variant: normal;
    font-size: 2em;
  }
  .discover__text {
    display: block;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    text-align: center;
    line-height: 35px;
    font-style: normal;
    font-weight: 400;
    color: var(--title-color);
    text-decoration: none;
    font-variant: normal;
    font-size: 16px;
    padding-bottom: 36px;
  }
  @media only screen and (max-width: 550px) {
    margin: 0 30px 25px;
  }
}

.discover__content {
  display: flex;
  flex-wrap: wrap;
}
