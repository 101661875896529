@import "../../utils/theme.scss";

* {
  margin: 0;
  padding: 0;
}

html {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
}

a {
  text-decoration: none;
}

.app {
  width: 100%;
  background-color: var(--second-bg-color);
  transition: 0.5s;
}
.content {
  width: 100%;
}
.PaddingTop {
  line-height: 90px;
  @media only screen and (max-width: 1024px) {
    line-height: 60px;
  }
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--phone-top-bottom-color);
}

::-webkit-scrollbar {
  width: 10px;
  background-color: var(--phone-top-bottom-color);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--select-bg-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--button-bg-color);
}
