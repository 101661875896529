@import "../../utils/theme.scss";

.district__image__box__content {
  margin: 0 auto;
}
.district__image__box {
  display: flex;
  position: relative;
  object-fit: fill;
  margin: 12.5px 0;
  cursor: pointer;
  &__image {
    position: relative;
    height: auto;
    border-radius: 5px;
    height: 100%;
  }
  .district__image__box__toptext {
    opacity: 0;
    position: absolute;
    z-index: 1;
    text-align: center;
    padding: 6px;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    color: #5d5d5d;
    border-radius: 5px;
    left: 25px;
    top: -10px;
    margin: 10px 0;
    transition: 0.5s;
  }
  .district__image__box__shadow {
    opacity: 0;
    position: absolute;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.37) 35%,
      rgba(0, 0, 0, 0.8) 100%
    );
    border-radius: 5px;
    top: 0px;
    transition: 0.5s;
  }
  .district__image__box__text {
    display: flex;
    position: absolute;
    z-index: 1;
    bottom: 30px;
    color: var(--white-title-font-color);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.9), 0 0 4px rgba(0, 0, 0, 0.9);
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-size: 20px;
    font-weight: 700;
    padding: 5px;
    white-space: pre-line;
    text-align: left;
  }
  @media only screen and (max-width: 550px) {
    .district__image__box__toptext {
      left: 50px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .district__image__box__toptext {
      opacity: 1;
      top: 10px;
      transition: 0.5s;
    }
  }
  &:hover {
    .district__image__box__toptext {
      opacity: 1;
      top: 10px;
      transition: 0.5s;
    }
    .district__image__box__shadow {
      opacity: 1;
      transition: 0.5s;
    }
    @media only screen and (max-width: 550px) {
      .district__image__box__text {
        left: 50px;
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .district__image__box {
    width: 100%;
  }
  .district__image__box__content {
    width: 100%;
    padding: 0 30px;
  }
  .district__image__box__image {
    width: 100%;
    height: 100%;
  }
  .district__image__box__shadow {
    width: calc(100% - 60px);
    height: 100%;
    left: 30px;
  }
  .district__image__box__text {
    left: 50px;
    width: 80%;
  }
}
@media only screen and (min-width: 550px) {
  .district__image__box {
    width: calc(100% / 2);
  }
  .district__image__box__content {
    width: 100%;
    padding: 0 12.5px;
  }
  .district__image__box__image {
    width: 100%;
    height: 100%;
  }
  .district__image__box__shadow {
    width: calc(100% - 25px);
    height: 100%;
    left: 12.5px;
  }
  .district__image__box__text {
    left: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .district__image__box {
    width: calc(100% / 3);
  }
  .district__image__box__content {
    width: 100%;
    padding: 0 12.5px;
  }
  .district__image__box__image {
    width: 100%;
    height: 100%;
  }
  .district__image__box__shadow {
    width: calc(100% - 25px);
    height: 100%;
    left: 12.5px;
  }
  .district__image__box__text {
    left: 20px;
  }
}
@media only screen and (min-width: 530px) and (max-width: 1260px) {
  .district__image__box__text {
    font-size: 16px !important;
    width: 90%;
  }
}
