@import "../../../utils/theme.scss";

.discover__content__card {
  margin-bottom: 30px;
  width: 100%;
  @media only screen and (max-width: 550px) {
    .discover__content__text {
      width: 100%;
    }
    .discover__content__box .apadding {
      opacity: 1;
    }
  }
  @media only screen and (min-width: 550px) {
    width: calc(100% / 2);
    .discover__content__box .apadding {
      opacity: 1;
    }
  }
  @media only screen and (min-width: 768px) {
    width: calc(100% / 2);
    .discover__content__box .apadding {
      opacity: 1;
    }
  }
  @media only screen and (min-width: 1024px) {
    width: calc(100% / 4);
    .discover__content__box .apadding {
      opacity: 0;
    }
  }
  @media only screen and (min-width: 1280px) {
    width: calc(100% / 4);
    .discover__content__box .apadding {
      opacity: 0;
    }
  }
}
.discover__content__card__pointer {
  cursor: help;
}
.discover__content__box {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  vertical-align: 0;
  margin: 0 12.5px;
  overflow: hidden;
  .discover__content__text__image {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  .district__image__box__shadow {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.37) 35%,
      rgba(0, 0, 0, 0.8) 100%
    );
    border-radius: 5px;
    top: 0px;
    transition: 0.5s;
  }
  .atoppadding {
    position: absolute;
    display: flex;
    bottom: 30px;
    color: var(--white-font-color);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.9), 0 0 4px rgba(0, 0, 0, 0.9);
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin: 0 12.5px;
    padding: 5px;
    transition: 0.5s;
  }
  .apadding {
    position: absolute;
    opacity: 0;
    z-index: 1;
    bottom: -5px;
    margin: 0 12.5px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.9), 0 0 4px rgba(0, 0, 0, 0.9);
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 3px;
    color: var(--white-font-color);
    transition: 0.5s;
  }
  &:hover {
    .atoppadding {
      position: absolute;
      display: flex;
      z-index: 1;
      transform: translateY(-300%);
      transition: 0.5s;
    }
    .apadding {
      opacity: 1;
      position: absolute;
      bottom: 20px;
      transition: 0.5s;
    }
    .district__image__box__shadow {
      opacity: 1;
      transition: 0.5s;
    }
  }
  @media only screen and (max-width: 1024px) {
    .atoppadding {
      position: absolute;
      display: flex;
      z-index: 1;
      transform: translateY(-300%);
      transition: 0.5s;
    }
    .apadding {
      opacity: 1;
      position: absolute;
      bottom: 20px;
      transition: 0.5s;
    }
    .district__image__box__shadow {
      opacity: 1;
      transition: 0.5s;
    }
  }
}
