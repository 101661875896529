@import "../../utils/theme.scss";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.onlineMap_main {
  position: absolute;
  z-index: 2;
  background-color: var(--primary-bg-color);
  width: 100%;
  height: calc(100vh - 90px);
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    height: calc(100vh - 60px);
  }
}

.onlineMap_nav {
  position: absolute;
  z-index: 4;
  left: 10px;
  top: 90px;
}

.onlineMap_copy {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--second-bg-color);
  border: 2px solid var(--button-bg-color);
  border-radius: 2px;
  width: 38px;
  height: 38px;
  cursor: pointer;
  margin-top: 16px;
  &:hover {
    transition: 0.3s;
    background-color: var(--primary-button-hover-bg-color);
  }
  &-active {
    transition: 0.3s;
    background-color: var(--success-green);
  }
}

.onlineMap_copy_icon {
  width: 25px;
  height: 25px;
  fill: var(--second-button-title-color);
}

.Top__nav {
  text-align: right;
  font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #5d5d5d;
  width: 65px;
  height: 38px;
  background-color: var(--second-bg-color);
  .Top__nav__list {
    height: 24px;
    line-height: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .Top__nav_col__right {
    display: block;
    position: relative;
    text-align: center;
    list-style: none;
    height: 38px;
    border: 2px solid var(--button-bg-color);
    border-radius: 2px;
    transition: 0.5s;
    &:hover {
      transition: 0.3s;
      background-color: var(--primary-button-hover-bg-color);
      .Top__nav__right_title_under {
        display: block;
        .Top__nav__right_subtitle {
          transition: 0.5s;
        }
      }
    }
    .Top__nav__right_subtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 45px;
      color: var(--third-button-title-color);
      border: 1px solid var(--main-between-line-color);
      border-radius: 2px;
      background-color: var(--top-nav-bg-color);
      text-align: left;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background-color: var(--primary-button-hover-bg-color);
        transition: 0.5s;
      }
    }
  }

  .Top__nav__right_title_under {
    display: none;
    position: absolute;
    padding-top: 2px;
    left: 0;
  }
  .Top__nav__right_title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 37px;
    color: var(--second-button-title-color);
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
  }
  .Top__nav__right_title_icon {
    color: var(--second-button-title-color);
    fill: var(--second-button-title-color);
  }
  .Top__nav__right_title_content {
    padding-left: 2px;
  }
}
