@import "../../../../utils/theme.scss";

.GeneralPageContainer__title__content {
  .GeneralPageContainer__title {
    padding: 16px 0 15px 0;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: var(--title-color);
    text-decoration: none;
    font-variant: normal;
    font-size: 2.5em;
    border-bottom: 1px solid var(--border-bottom-color);
  }
  .GeneralPageContainer__content__subTitle {
    color: var(--title-color);
    font-weight: 300;
    font-size: 18px;
    vertical-align: 0;
    border-bottom: 1px solid var(--border-bottom-color);
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .GeneralPageContainer__title__banner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .GeneralPageContainer__title__banner_img {
    width: 100%;
    max-width: 500px;
    object-fit: cover;
  }
  @media only screen and (max-width: 550px) {
    .GeneralPageContainer__title {
      font-size: 2em;
      text-align: left;
    }
  }
}
.GeneralPageContainer__content {
  padding: 20px 0;
  .GeneralPageContainer__content__subTitle {
    width: 100%;
    line-height: 28px;
    padding: 20px 0;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-weight: 500;
    color: var(--subtitle-bg);
    font-size: 28px;
  }
  .GeneralPageContainer__content__text {
    font-weight: 300;
    color: var(--title-color);
    font-size: 18px;
    white-space: pre-wrap;
    margin-top: 15px;
  }
  .GeneralPageContainer__content__image {
    width: 100%;
    margin: 15px 0;
    .GeneralPageContainer__content_contain {
      display: flex;
      .GeneralPageContainer__content__image_container_img {
        width: 100%;
        object-fit: cover;
      }
    }
    .GeneralPageContainer__content__image_text {
      display: flex;
      align-items: center;
      font-weight: 300;
      color: var(--title-color);
      font-size: 15px;
      &::before {
        content: "▶";
        margin-right: 8px;
        font-size: 10px;
        line-height: 30px;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .GeneralPageContainer__content__subTitle {
      font-size: 23px;
      padding: 15px 0px;
    }
    .GeneralPageContainer__content__text {
      font-size: 16px;
    }
  }
  .discover__news_display_table {
    width: 100%;
    margin: 15px 0;
    .discover__news_display_table_row {
      border: 1px solid var(--main-between-line-color);
      .discover__news_display_table_col {
        font-size: 18px;
        font-weight: 300;
        color: var(--title-color);
        padding: 8px 14px;
        min-width: 200px;
        &-first {
          min-width: 105px;
          border-right: 1px solid var(--main-between-line-color);
        }
        &-second {
          min-width: 155px;
        }
        &-third {
          width: 100%;
          max-height: 65px;
        }
        @media only screen and (max-width: 550px) {
          font-size: 16px;
          padding: 8px 8px;
          &-first {
            min-width: 85px;
          }
          &-second {
            min-width: 96px;
          }
          &-third {
            max-height: 51px;
          }
        }
      }
    }
  }
}
.line {
  width: 100%;
  hr {
    border: 0;
    border-top: rgba(0, 0, 0, 0.18) 1px solid;
    margin: 2em 0 !important;
  }
}

.GeneralPageContainer__content__route_container {
  width: 100%;
  .line {
    width: 100%;
    hr {
      border: 0;
      border-top: rgba(0, 0, 0, 0.18) 1px solid;
      margin: 2em 0 !important;
    }
    &:last-child {
      display: none;
    }
  }
}
.GeneralPageContainer__content__route {
  display: flex;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  .GeneralPageContainer__content__route_left {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 128px;
    margin-right: 10px;
    .GeneralPageContainer__content__route_left_logo {
      object-fit: cover;
    }
  }
  .GeneralPageContainer__content__route_right {
    .GeneralPageContainer__content__route_right_subTitle {
      width: 100%;
      line-height: 28px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-weight: 500;
      color: var(--subtitle-bg);
      font-size: 23px;
    }
    .GeneralPageContainer__content__route_right_text {
      font-weight: 300;
      color: var(--title-color);
      font-size: 18px;
      white-space: pre-wrap;
      margin-top: 15px;
    }
    @media only screen and (max-width: 550px) {
      margin-top: 20px;
      .GeneralPageContainer__content__route_right_subTitle {
        font-size: 23px;
      }
      .GeneralPageContainer__content__route_right_text {
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    display: block;
  }
}
