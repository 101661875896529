@import "../../utils/theme.scss";

.main {
  width: 100%;
  max-width: 1000px;
  min-height: 90vh;
  margin: 0 auto;
  background-color: var(--primary-bg-color);
  @media only screen and (max-width: 550px) {
  }
  @media only screen and (min-width: 550px) {
  }
  @media only screen and (min-width: 768px) {
    max-width: 720px;
    border-left: 1px solid var(--main-between-line-color);
    border-right: 1px solid var(--main-between-line-color);
  }
  @media only screen and (min-width: 1024px) {
    max-width: 980px;
    border-left: 1px solid var(--main-between-line-color);
    border-right: 1px solid var(--main-between-line-color);
  }
  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
    border-left: 1px solid var(--main-between-line-color);
    border-right: 1px solid var(--main-between-line-color);
  }
}
