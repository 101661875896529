@import "../../utils/theme.scss";

.main__banner {
  background-size: 100vw;
  width: 100%;
  height: calc(100vw / 3.49);
  background-position: center;
}

.main__banner__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}
