@import "../../utils/theme.scss";

.container {
  width: 100%;
  justify-content: center;
}

.district__image {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  //justify-content: space-between;
}
