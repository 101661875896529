@import "../../../utils/theme.scss";
.PhoneTop__mainnav__container {
  z-index: 100;
  display: flex;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  top: 0;
  transition: 0.5s;
  transform: translateX(-1000px);
  &-open {
    z-index: 100;
    display: flex;
    position: fixed;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    @media only screen and (min-width: 768px) {
      display: flex;
    }
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
  @media only screen and (min-width: 768px) {
    display: flex;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.PhoneTop__mainnav__container__left {
  display: block;
  width: 80%;
  height: 100%;
  max-width: 100%;
  background-color: var(--phone-top-nav-bg-color);
  transition: 0.5s;
  padding: 14px;
  overflow-y: auto;
}
.PhoneTop__mainnav__container__col {
  width: 100%;
  margin-bottom: 20px;
  .PhoneTop__mainnav__container__col__title {
    font-size: 17px;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    color: var(--phone-top-nav-col-title-color);
    text-align: left;
    padding: 25px 0;
  }
  .PhoneTop__mainnav__container__col__a__darkmode {
    display: flex;
    .PhoneTop__mainnav__container__col__a__darkmode__text {
      font-size: 14px;
      padding: 12px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      color: var(--phone-top-nav-col-text-color);
      text-align: left;
    }
  }
  .PhoneTop__mainnav__container__col__a {
    display: block;
    text-decoration-line: none;
    width: 100%;
    transition: background-color 0.5s ease, color 0.5s ease;
    box-sizing: border-box;
    .PhoneTop__mainnav__container__col__a__menu {
      font-size: 14px;
      padding: 12px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      color: var(--phone-top-nav-col-text-color);
      text-align: left;
    }
    .PhoneTop__mainnav__container__col__a__language {
      cursor: pointer;
      font-size: 14px;
      padding: 12px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      color: var(--phone-top-nav-col-text-color);
      text-align: left;
    }
    &:hover {
      transition: 0.5s;
      color: var(--subject-blue);
    }
  }
}
.PhoneTop__mainnav__container__col__line {
  width: 1px;
  height: 80%;
  margin: 0 20px;
  background-color: var(--phone-top-nav-col-line-color);
  transition: 0.5s;
}

.PhoneTop__mainnav__container__shadow {
  display: flex;
  height: 100%;
  width: 20%;
  //background-color: rgba(68, 68, 68, 0.281);
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.05) 35%,
    rgba(0, 0, 0, 0.24) 100%
  );
  z-index: 0;
}

.PhoneTop__switch {
  position: relative;
  display: inline-block;
  left: 80px;
  width: 60px;
  height: 34px;
}

.switch input,
.PhoneTop__switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-button-hover-bg-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--subject-blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--subject-blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
