@import "../../../../utils/theme.scss";

.title__content {
  .title {
    padding: 16px 0 15px 0;
    font-weight: 500;
    color: var(--title-color);
    font-size: 2.5em;
  }
  .subTitle {
    color: var(--title-color);
    font-weight: 300;
    font-size: 18px;
    vertical-align: 0;
    border-bottom: 1px solid var(--border-bottom-color);
    padding-bottom: 30px;
  }
  @media only screen and (max-width: 550px) {
    .title {
      font-size: 2em;
      text-align: left;
    }
    .subTitle {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 1280px) {
    .subTitle {
      color: var(--title-color);
      font-size: 16px;
      vertical-align: 0;
    }
  }
}
.content {
  .content_title {
    color: var(--title-color);
    font-weight: 500;
    font-size: 18px;
    vertical-align: 0;
    margin-top: 50px;
  }
  .content_text {
    line-height: 25px;
    font-weight: 300;
    color: var(--title-color);
    font-size: 18px;
    white-space: pre-wrap;
    margin-top: 15px;
    &-small {
      @extend .content_text;
      font-size: 16px;
      margin: 15px 0 0 35px;
    }
  }
  @media only screen and (max-width: 550px) {
    .content_title {
      font-size: 16px;
      padding: 15px 0px;
    }
    .content_text {
      font-size: 16px;
    }
  }
}
