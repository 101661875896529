@import "../../../../utils/theme.scss";

.railwayHistroy__title__content {
  .railwayHistroy__title {
    padding: 16px 0 15px 0;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: var(--title-color);
    text-decoration: none;
    font-variant: normal;
    font-size: 2.5em;
    border-bottom: 1px solid var(--border-bottom-color);
    margin-bottom: 30px;
  }
  .railwayHistroy__subTitle {
    color: var(--title-color);
    font-weight: 300;
    font-size: 18px;
    vertical-align: 0;
    border-bottom: 1px solid var(--border-bottom-color);
    padding-bottom: 30px;
  }
  .railwayHistory__route_search {
    border-bottom: 1px solid var(--border-bottom-color);
    padding-bottom: 20px;
    .railwayHistory__route_search_title {
      width: 100%;
      line-height: 28px;
      padding: 20px 0;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-weight: 500;
      color: var(--subtitle-bg);
      font-size: 28px;
    }
    .railwayHistory__route_search_autocomplete {
      align-items: center;
      width: 100%;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .railwayHistory__route_search_autocomplete_item {
        display: flex;
        .railwayHistory__route_search_autocomplete_item_text {
          display: flex;
          align-items: center;
          font-weight: 300;
          color: var(--title-color);
          font-size: 18px;
          vertical-align: 0;
          margin-right: 8px;
        }
      }
    }
    .railwayHistory__route_search_function {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 20px;
      gap: 10px;
    }

    @media only screen and (max-width: 1280px) {
      .railwayHistory__route_search_autocomplete {
        .railwayHistory__route_search_autocomplete_item {
          display: block;
          .railwayHistory__route_search_autocomplete_item_text {
            margin-bottom: 8px;
          }
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      .railwayHistory__route_search_function {
        display: grid;
      }
    }
  }
  .railwayHistroy__title__map {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .railwayHistroy__title__map_name {
    width: 100%;
    text-align: center;
    line-height: 28px;
    padding: 20px 0;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-weight: 500;
    color: var(--subtitle-bg);
    font-size: 28px;
  }
  .railwayHistroy__title__map__banner {
    position: relative;
    display: flex;

    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: center;
  }
  .railwayHistroy__title__map__banner_container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    max-width: 900px;
  }
  .railwayHistroy__title__map__banner_container_img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    top: 0;
  }
  @media only screen and (max-width: 550px) {
    .railwayHistroy__title {
      font-size: 2em;
      text-align: left;
    }
  }
}
.railwayHistroy__content {
  .railwayHistroy__content__subTitle {
    width: 100%;
    line-height: 28px;
    padding: 20px 0;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-weight: 500;
    color: var(--subtitle-bg);
    font-size: 28px;
  }
  .railwayHistroy__content__text {
    font-weight: 300;
    color: var(--title-color);
    font-size: 18px;
    white-space: pre-wrap;
    margin-top: 15px;
  }
  @media only screen and (max-width: 550px) {
    .railwayHistroy__content__subTitle {
      font-size: 23px;
      padding: 15px 0px;
    }
    .railwayHistroy__content__text {
      font-size: 16px;
    }
  }
}
.line {
  width: 100%;
  hr {
    border: 0;
    border-top: rgba(0, 0, 0, 0.18) 1px solid;
    margin: 2em 0 !important;
  }
}

.railwayHistroy__content__route_container {
  width: 100%;
  .line {
    width: 100%;
    hr {
      border: 0;
      border-top: rgba(0, 0, 0, 0.18) 1px solid;
      margin: 2em 0 !important;
    }
    &:last-child {
      display: none;
    }
  }
}
.railwayHistroy__content__route {
  display: flex;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  .railwayHistroy__content__route_left {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 128px;
    margin-right: 10px;
    .railwayHistroy__content__route_left_logo {
      object-fit: cover;
    }
  }
  .railwayHistroy__content__route_right {
    .railwayHistroy__content__route_right_subTitle {
      width: 100%;
      line-height: 28px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-weight: 500;
      color: var(--subtitle-bg);
      font-size: 23px;
    }
    .railwayHistroy__content__route_right_text {
      font-weight: 300;
      color: var(--title-color);
      font-size: 18px;
      white-space: pre-wrap;
      margin-top: 15px;
    }
    @media only screen and (max-width: 550px) {
      margin-top: 20px;
      .railwayHistroy__content__route_right_subTitle {
        font-size: 23px;
      }
      .railwayHistroy__content__route_right_text {
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    display: block;
  }
}
