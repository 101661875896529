@import "../../utils/theme.scss";

.containers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--second-bg-color);
}
.containersMain {
  @extend .containers;
  position: fixed;
  z-index: 2;
}
.containersDiv {
  @extend .containers;
  position: absolute;
  z-index: 1;
}
.loader_container {
}
.loader_message {
  display: flex;
  justify-content: center;
  color: var(--subject-blue);
  font-family: "Noto Sans TC", sans-serif;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 300;
  margin: 5px;
}
.loader_container_top {
  display: flex;
  justify-content: center;
}
.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--second-bg-color);
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--subject-blue) transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
