@import "../../../utils/theme.scss";

.main__content__sideMenu__content {
  width: 100%;
}
.main__content__sideMenu__content__title {
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 10px 0 5px 5px;
  font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: var(--subtitle-bg);
  text-decoration: none;
  font-variant: normal;
  font-size: 18px;
}
