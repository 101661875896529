@import "../../utils/theme.scss";

.sortNav__input {
  position: absolute;
  left: -9999em;
  top: -9999em;

  &:checked + .sortNav__btn {
    background-color: var(--second-bottom-hover-bg-color);
    .sortNav__text {
      color: var(--second-bottom-hover-color);
      transition: 0.5s;
    }
  }
}
.sortNav__btn {
  background-color: var(--second-bottom-bg-color);
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  .sortNav__text {
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    color: var(--second-bottom-color);
    text-decoration: none;
    font-variant: normal;
    font-size: 17px;
    padding: 6px 10px;
    transition: 0.5s;
  }
  &:hover {
    background-color: var(--second-bottom-hover-bg-color);
    .sortNav__text {
      color: var(--second-bottom-hover-color);
      transition: 0.5s;
    }
  }
}
