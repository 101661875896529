@import "../../utils/theme.scss";

.resident__box {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 25px 0;
  width: 20%;
  line-height: 30px;
  font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: var(--subtitle-bg);
  background-color: transparent;
  font-variant: normal;
  font-size: 25px;
  vertical-align: 0;
  cursor: pointer;
  transition: 0.5s;
  .resident__box__character {
    display: block;
    text-align: center;
    width: 100%;
    .resident__box__character__image {
      width: auto;
      height: auto;
    }
    .resident__box__character__title {
      margin: 10px 0;
      line-height: 20px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-variant: normal;
      font-size: 17px;
      vertical-align: 0;
    }
  }
  &:hover {
    color: #177cc4;
    background-color: var(--second-bottom-hover-color);
    transition: 0.5s;
  }
}
@media only screen and (max-width: 500px) {
  .resident__box {
    width: 50%;
  }
}
@media only screen and (min-width: 550px) {
  .resident__box {
    width: calc(100% / 2);
  }
}
@media only screen and (min-width: 768px) {
  .resident__box {
    width: calc(100% / 3);
  }
}
@media only screen and (min-width: 1024px) {
  .resident__box {
    width: calc(100% / 4);
  }
}
@media only screen and (min-width: 1280px) {
  .resident__box {
    width: calc(100% / 5);
  }
}
#residentPopUpLayer {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000d8;
  .residentPopUpLayer__container {
    display: block;
    width: 75%;
    height: 75%;
    overflow-y: auto;
    background-color: var(--popup-bg-color);
    #data_username_title {
      padding-top: 30px;
      text-align: center;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-style: normal;
      font-weight: 500;
      color: var(--title-color);
      background-color: transparent;
      text-decoration: none;
      font-variant: normal;
      font-size: 30px;
      vertical-align: 0;
      text-transform: uppercase;
    }
    @media only screen and (max-width: 320px) {
      #data_username_title {
        font-size: 20px;
      }
    }
    .residentPopUpLayer__container__main {
      height: 86.5%;
    }
    .residentPopUpLayer__container__error {
      display: none;
      justify-content: center;
      align-items: center;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-style: normal;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: top;
      color: var(--title-color);
      font-weight: 400;
      font-variant: normal;
      font-size: 50px;
      line-height: 75px;
      .residentPopUpLayer__container__error__title {
        display: block;
        padding: 20px;
      }
    }
    .residentPopUpLayer__container__data {
      padding: 60px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .residentPopUpLayer__container__data__img {
        position: fixed; /*固定位置定位*/
        top: 30%; /*距離上方的位置*/
        left: 25%; /*距離左方的位置*/
        display: block;
        width: 20%;
        #data_face_img {
          width: 60%;
          height: 60%;
        }
      }

      .residentPopUpLayer__container__data__table {
        display: block;
        position: relative;
        padding: 0px;
        left: 18%;
        width: 65%;

        .data__table {
          line-height: 40px;
          font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
          font-style: normal;
          font-weight: 400;
          color: var(--subtitle-bg);
          font-variant: normal;
          font-size: 20px;
          width: 100%;
          text-align: left;
          vertical-align: top;
          .data__table__datatitle {
            border-right: 1px solid #b4b4b4;
          }

          .data__table__datainfo {
            width: 60%;
            height: 38.38px;
            padding-left: 30px;
          }
          #data_project {
            overflow: auto;
            max-height: 10%;
          }
        }
        @media only screen and (max-width: 1280px) {
          .data__table {
            font-size: 17px;
          }
        }
        @media only screen and (max-width: 500px) {
          .data__table {
            font-size: 15px;
            .data__table__datainfo {
              padding-left: 10px;
            }
          }
        }
        @media only screen and (max-width: 400px) {
          .data__table {
            font-size: 13px;
            .data__table__datainfo {
              padding-left: 8px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1280px) {
      .residentPopUpLayer__container__error {
        font-size: 50px;
      }
      .residentPopUpLayer__container__data {
        .residentPopUpLayer__container__data__table {
          padding: 0px;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      .residentPopUpLayer__container__error {
        font-size: 40px;
      }
      .residentPopUpLayer__container__data {
        padding: 0;
        flex-direction: column;
        .residentPopUpLayer__container__data__img {
          position: relative;
          top: 40%; /*距離上方的位置*/
          left: 10%; /*距離左方的位置*/
          display: block;
          flex: 3 1 20%;
          order: 1;
          width: 40%;
        }
        .residentPopUpLayer__container__data__table {
          display: block;
          position: static;
          left: 0%;
          flex: 3 1 20%;
          order: 2;
          line-height: 20px;
          width: 80%;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .residentPopUpLayer__container__error {
        font-size: 30px;
      }
    }
    @media only screen and (max-width: 500px) {
      .residentPopUpLayer__container__error {
        font-size: 20px;
      }
    }

    .residentPopUpLayer_closeLayer {
      position: absolute;
      cursor: pointer;
      right: 30px;
      top: 30px;
      width: 32px;
      height: 32px;
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 2px;
        background-color: #fff;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.data__title {
  padding: 20px 0;
  text-align: center;
  line-height: 35px;
  font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: var(--title-color);
  background-color: transparent;
  text-decoration: none;
  font-variant: normal;
  font-size: 30px;
  vertical-align: 0;
}
@media only screen and (max-width: 500px) {
  .data {
    padding: 20px;
  }
  .data__title {
    font-size: 20px;
  }
}
