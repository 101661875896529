@import "../../utils/theme.scss";

.switch {
  position: relative;
  display: inline-block;
  left: 80px;
  width: 60px;
  height: 34px;
  @media (min-width: 1024px) {
    position: absolute;
    left: 155px;
    ::after {
      display: block;
      content: " ";
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid var(--border-bottom-color);
      transform: translateY(-200%) translateX(390%);
    }
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-button-hover-bg-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--subject-blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--subject-blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
