@import "../../../../utils/theme.scss";

.BCCTB__title__content {
  .BCCTB__title {
    padding: 16px 0 15px 0;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: var(--title-color);
    text-decoration: none;
    font-variant: normal;
    font-size: 2.5em;
    border-bottom: 1px solid var(--border-bottom-color);
    margin-bottom: 30px;
  }
  .BCCTB__title__banner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .BCCTB__title__banner_img {
    width: 100%;
    max-width: 500px;
    object-fit: cover;
  }
  @media only screen and (max-width: 550px) {
    .BCCTB__title {
      font-size: 2em;
      text-align: left;
    }
  }
}
.BCCTB__content {
  .BCCTB__content__subTitle {
    width: 100%;
    line-height: 28px;
    padding: 20px 0;
    font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
    font-weight: 500;
    color: var(--subtitle-bg);
    font-size: 28px;
  }
  .BCCTB__content__text {
    font-weight: 300;
    color: var(--title-color);
    font-size: 18px;
    white-space: pre-wrap;
    margin-top: 15px;
  }
  @media only screen and (max-width: 550px) {
    .BCCTB__content__subTitle {
      font-size: 23px;
      padding: 15px 0px;
    }
    .BCCTB__content__text {
      font-size: 16px;
    }
  }
}
.line {
  width: 100%;
  hr {
    border: 0;
    border-top: rgba(0, 0, 0, 0.18) 1px solid;
    margin: 2em 0 !important;
  }
}

.BCCTB__content__route_container {
  width: 100%;
  .line {
    width: 100%;
    hr {
      border: 0;
      border-top: rgba(0, 0, 0, 0.18) 1px solid;
      margin: 2em 0 !important;
    }
    &:last-child {
      display: none;
    }
  }
}
.BCCTB__content__route {
  display: flex;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  .BCCTB__content__route_left {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 128px;
    margin-right: 10px;
    .BCCTB__content__route_left_logo {
      object-fit: cover;
    }
  }
  .BCCTB__content__route_right {
    .BCCTB__content__route_right_subTitle {
      width: 100%;
      line-height: 28px;
      font-family: "Noto Sans TC", "Noto Sans JP", "微軟正黑體", sans-serif;
      font-weight: 500;
      color: var(--subtitle-bg);
      font-size: 23px;
    }
    .BCCTB__content__route_right_text {
      font-weight: 300;
      color: var(--title-color);
      font-size: 18px;
      white-space: pre-wrap;
      margin-top: 15px;
    }
    @media only screen and (max-width: 550px) {
      margin-top: 20px;
      .BCCTB__content__route_right_subTitle {
        font-size: 23px;
      }
      .BCCTB__content__route_right_text {
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    display: block;
  }
}
